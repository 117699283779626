import React, { Component } from 'react';
import { Link } from "gatsby"
import { css } from "@emotion/core";
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet";
import { AuthUserContext } from '../components/Session';
import SignOutButton from '../components/SignOut';
import Navlinks from '../components/nav';
import * as ROUTES from '../constants/routes';
import LoadingImg from '../images/load_icon.gif';
import dynamic from "next/dynamic";
import { Offline, Online } from 'react-detect-offline';
import SignInForm, {SignInSaml} from '../components/SignIn';

const PWAPrompt = dynamic(() => import("react-ios-pwa-prompt"), {
  ssr: false
});

const LoaderOver = () => (
  <div className="loadingScreen">
    <img src={LoadingImg} alt="Loading..." />
  </div>
);

const AuthUserSkip = ({ authUser }) => (
  <React.Fragment>
  <SEO title="Compass Minerals SaltSmart" />
      <Helmet defer={true}>
        <body className={'home loggedInUser userID_'+authUser.uid+'_endID'} />
      </Helmet>
      <header className="mainHead flexContainer homeHead  faCenter fjCenter">      
        <div className="fCol12 menuCtrl">
          <div className="headerTitle">Home</div>
          <Navlinks />
        </div>
      </header>
      <section id="home-content" className="flexContainer secondaryTab">
        <div className="fCol12 maxTablet">
          <div className="flexContainer homeInner">
            <span className="icon-avatar"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
            <h1>Welcome {authUser.username}</h1>
            <Link to={ROUTES.US}>U.S. Products</Link>
            <Link to={ROUTES.CA}>Canada Products</Link>
            <Link to={ROUTES.COMPANY}>Company Information</Link>
            <Link className="portfolioLink" to={ROUTES.PORTFOLIOS}>My Portfolios</Link>
            <SignOutButton />            
          </div>
          
        </div>
        <div className="compassLogo"/>
      </section>    
      
  </React.Fragment>
);

const InitialLogin = () => (
  <React.Fragment>    
    <SEO title="Compass Minerals SaltSmart" />
    <Helmet defer={true}>
      <body className="login v2" />
    </Helmet>
    <header className="mainHead flexContainer homeHead  faCenter fjCenter">      
      <div className="fCol12 menuCtrl">
        <div className="headerTitle initialLogin">Home</div>
      </div>
    </header>
    <section id="home-content" className="flexContainer secondaryTab">
      <div className="fCol12 maxTablet">
        <div className="flexContainer ">
          <div css={css`text-align:center;`}>
            <SignInSaml />
            <Online>
              <div className="btn">
                <Link to={ROUTES.SIGN_IN} >Alternate Login</Link>
              </div>  
            </Online>
            <Offline>
              <div className="btn disabled">
                <a>Alternate Login</a>
              </div>
            </Offline>
          </div>
          <br />
          <div css={css`text-align:center;`}>
            <p css={css`text-transform:uppercase;`}>
              Don't Have a Login?<br />
              <Link to={ROUTES.GUEST} css={css`color:#fff;`}>Continue as Guest</Link>
            </p>                
          </div>
        </div>
      </div>
      <div className="compassLogo"/>
    </section> 
   
  </React.Fragment>
);


class Start extends Component {
  constructor(props){
    super(props)
    this.state = {
      profileExist: true,
         loading: true,

    }
 }
 async componentDidMount() {
  this.setState({profileExist: false})
  setTimeout(() => {
    this.setState({profileExist: true})
  }, 2000);
  }
  render(){
    //if (this.state.loading) return LoadingMessage();
    return(
      <Layout>
        <AuthUserContext.Consumer>
          {authUser =>
            authUser ? (  
              this.state.profileExist === true ? <AuthUserSkip authUser={authUser} /> : <LoaderOver />         
              ) : (
                this.state.profileExist === true ?  <InitialLogin />  : <LoaderOver />           
              )
          }
        </AuthUserContext.Consumer>  
        <PWAPrompt promptOnVisit={1} timesToShow={30} copyClosePrompt="Exit" permanentlyHideOnDismiss={false} copyBody="The SaltSmart app must be added to your home screen to use it in fullscreen, use it while offline and receive notifications." copyShareButtonLabel="1) Tap the 'Share' button."/>
      </Layout>
    )
 }
}

export default Start;
